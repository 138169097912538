import { template as template_7c152bf977cc40d683495bdc74b14c0d } from "@ember/template-compiler";
const WelcomeHeader = template_7c152bf977cc40d683495bdc74b14c0d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
