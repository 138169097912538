import { template as template_2420a2af60dc4d9ab90ddb73d280d066 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2420a2af60dc4d9ab90ddb73d280d066(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
