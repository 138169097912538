import { template as template_5162d88af56c47e3b8bf247aa23d877b } from "@ember/template-compiler";
const FKLabel = template_5162d88af56c47e3b8bf247aa23d877b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
