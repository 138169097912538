import { template as template_107191bd8a134c87aa1d33d1a2c20717 } from "@ember/template-compiler";
const SidebarSectionMessage = template_107191bd8a134c87aa1d33d1a2c20717(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
